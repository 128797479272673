import { useMemo } from 'react';

// Original code comes from Option 3 in https://stackoverflow.com/a/27747377
function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0');
}

export const useRandomString = (len: number = 20) => {
  return useMemo(() => {
    const arr = new Uint8Array(len / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
  }, [len]);
};
